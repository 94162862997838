<template>
  <div class="thanks">
    <el-row class="header" style="text-align: center;color: white"></el-row>

    <h2 style="text-align: center">Thank you for your cooperation</h2>
    <h2 style="text-align: center;background-color: gainsboro; height: 64px;line-height: 64px;width: 380px; margin: 0 auto">
      CODE：{{ code }}</h2>
  </div>
</template>

<script>
export default {
  name: "Thanks",
  data: function () {
    return {
      code: ""
    }
  },
  mounted() {
    if (localStorage.getItem('uuid')) {
      this.code = localStorage.getItem('code')
    }
  }
}
</script>

<style scoped>
.header {
  height: 64px;
  background-color: #1976D2;
}
</style>