<template>
  <div id="app">
    <router-view :key='$route.fullPath'></router-view>
  </div>
</template>

<style>
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>

<script>
export default {}
</script>