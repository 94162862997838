<template>
  <div class="about">
    <el-row class="header"></el-row>
    <el-row type="flex" justify="center">
      <el-col :span="22">
        <h1>Introduction</h1>
        <p>Thank you for participating in our task. Please select the image with the most effective de-raining from the
          list of images on each page.</p>

      </el-col>
    </el-row>
    <el-row style="text-align: center;">
      <el-col :span="24" v-for="(item,index) in model_info" :key="item" style="padding: 10px;">
        <el-button v-for="idx in 2" :key="idx" type="primary" @click="next(item,idx.toString())">GROUP{{ index + 1 }}: Worker{{
            idx
          }}
        </el-button>
      </el-col>
    </el-row>

  </div>
</template>
<script>
//import instance from "@/request";
export default {
  name: 'About',
  data: function () {
    return {
      model_info: ['tf', 'semi', 'DCSFN', 'MPRNet', 'NLEDN'],
    }
  },
  methods: {
    next: function (model, idx) {
      this.$router.push({name: 'Home', params: {'model': model, 'plan': idx}, query: {d: new Date().getTime()}})
    }
  }
}
</script>
<style scoped>
.header {
  height: 64px;
  background-color: #1976D2;
}
</style>
