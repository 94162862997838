<template>
  <div class="home">
    <el-row class="header" style="text-align: center;color: white"><h2>{{ current_point }}/20</h2></el-row>
    <el-row>
      <el-col :span="24" style="text-align: center"><h3>Please select the image with the fewest and least rain drops or
        rain streaks among the images on each page.</h3></el-col>
    </el-row>
    <el-row style="padding: 16px 0" type="flex" justify="center" align="middle">
      <el-col :span="1" style="text-align: center;">
        <el-button icon="el-icon-arrow-left" circle :disabled="prev_disabled" @click="prev"></el-button>
      </el-col>
      <el-col :span="22">
        <el-row>
          <el-row :gutter="10" type="flex" justify="center">
            <el-radio-group v-model="labeled_data['Group'+userinfo.group+'_'+current_point]" @change="check_attention">
              <el-col :span="spans" v-for="(img,index) in current_imgs" :key="index">
                <el-card :body-style="{ padding: '0px' }" v-if="current_img_num===4">
                  <div style="display: flex;justify-content:space-around">
                    <div :style="{height:img_height, textAlign:'left'}">
                      <el-image
                          style="height: 100%"
                          :src="img"
                          fit="contain"
                          :preview-src-list="current_imgs"
                      >
                      </el-image>
                    </div>
                    <div style="padding: 14px;display: flex;align-items: center">
                      <div class="bottom clearfix" style="text-align: center">
                        <el-radio :label="`Group${userinfo.group}_${current_point}_${index+1}`" border>
                          this is the best
                        </el-radio>
                      </div>
                    </div>
                  </div>
                </el-card>
                <el-card :body-style="{ padding: '0px' }" v-if="current_img_num===3">
                  <div :style="{height:img_height, textAlign:'left'}">
                    <el-image
                        style="height: 100%"
                        :src="img"
                        fit="contain"
                        :preview-src-list="current_imgs"
                    >
                    </el-image>
                  </div>
                  <div style="padding: 14px;">
                    <div class="bottom clearfix" style="text-align: center">
                      <el-radio :label="`${current_model}_${current_selected}_${index+1}`" border>
                        this is the best
                      </el-radio>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-radio-group>
          </el-row>
          <el-col :span="spans"></el-col>
        </el-row>
      </el-col>
      <el-col :span="1" style="text-align: center">
        <el-button icon="el-icon-arrow-right" circle :disabled="next_disabled" @click="next"></el-button>
      </el-col>
    </el-row>
    <el-row style="text-align: center;padding-bottom: 20px">
      <el-button type="primary" v-if="finish_51" @click="submit">Submit</el-button>
    </el-row>
    <!-- 弹出框 -->
    <el-dialog title="Demographic" :visible="dialogTableVisible"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
    >
      <el-form ref="registerForm" :model="userinfo" label-width="80px" :rules="rules">
        <el-form-item label="Age:" prop="age">
          <el-input v-model="userinfo.age" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="Gender:" prop="gender">
          <el-radio-group v-model="userinfo.gender">
            <el-radio-button label="female"></el-radio-button>
            <el-radio-button label="male"></el-radio-button>
            <el-radio-button label="other"></el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doRegister('registerForm')">Submit</el-button>
      </div>
    </el-dialog>
    <el-dialog title="A Kind Reminder:" :visible.sync="reminder"
    >
      <el-image :src="reminder_img"></el-image>
      <h3>Please pay attention to the details of the images and select the image with the fewest and least rain drops or
        rain streaks. Thank you!</h3>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="reminder=false">OK</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import instance from "@/request";

export default {
  name: 'Home',
  data: function () {
    let checkAge = (rule, value, callback) => {
      const regAge = /^[0-9]*$/
      if (regAge.test(value)) {
        if (value < 18) {
          callback(new Error('You must be at least 18 years old.'));
        } else {
          return callback()
        }
      } else {
        callback(new Error('Please enter number.'))
      }
    }
    return {
      pre_url: this.$route.params.group,
      group:'',
      //记录前一个选择
      pre_code: '',
      dialogTableVisible: true,
      attention_check: false,
      reminder: false,
      reminder_img: require("../assets/imgs/tips.jpg"),
      carousel: {
        height: '900px',
        span: 12
      },
      clientHeight: '',
      radio: '',
      rules: {
        age: [
          {required: true, message: 'Please enter your age.', trigger: 'blur'},
          {validator: checkAge, trigger: 'blur'}
        ],
        gender: [
          {required: true, message: 'Please select your gender.', trigger: 'change'},
        ],
      },
      userinfo: {
        age: '',
        gender: '',
        group: this.group,
      },
      models: ['tf', 'semi', 'DCSFN', 'MPRNet', 'NLEDN'],
      spans: 8,
      labeled_data: {},
      current_img_num: 3,
      current_model: "",
      current_pre: 0,
      current_point: 1,
      current_imgs: [],
      current_selected: null,
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    prev: function () {
      if (this.current_point !== 1) {

        this.current_point--
        this.create_imgs()
      }
    },
    check_attention: function () {
      let current_label = 'Group' + this.userinfo.group +'_'+ this.current_point;
      if (this.labeled_data[current_label].charAt(this.labeled_data[current_label].length - 1) === this.pre_code && this.pre_code === '1') {
        this.reminder = true
      } else {
        this.next()
      }
    },
    next: function () {
      //console.log(this.labeled_data)
      if (this.current_point !== 20) {
        let current_label = 'Group' + this.userinfo.group +'_'+ this.current_point;
        if (current_label in this.labeled_data) {
          this.pre_code = this.labeled_data[current_label].charAt(this.labeled_data[current_label].length - 1)
          this.current_point++
          this.create_imgs()
        } else {
          this.$message.error("Please select an image")
        }
      }
    },
    doRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //发送数据
          console.log(this.userinfo)
          let send_data = {
            age: this.userinfo.age,
            gender: this.userinfo.gender,
            init_time: new Date().getTime(),
            group: this.group,
          }
          instance.post("/add_user", send_data).then(res => {
            if (res.status === 200) {
              if (res.data.code === 1) {
                localStorage.setItem('uuid', res.data.uuid);
                if (!localStorage.getItem('uid')) {
                  localStorage.setItem('uid', res.data.uid);
                }
                switch (this.group) {
                  case 1:
                    localStorage.setItem('code', 'C67QE49F');
                    break;
                  case 2:
                    localStorage.setItem('code', 'C67QE49F');
                    break;
                  case 3:
                    localStorage.setItem('code', 'C67QE49F');
                    break;
                  case 4:
                    localStorage.setItem('code', 'C67QE49F');
                    break;
                  case 5:
                    localStorage.setItem('code', 'C67QE49F');
                    break;
                }
                this.dialogTableVisible = false;
                this.reminder = true;
              } else {
                //服务端返回错误
                this.$message.error(res.data.msg)
              }
            }
          }).catch((err) => {
            console.log(err)
            this.$message.error('ERROR:' + err.status + " Please try again later.")
          });
        } else {
          //表单验证
          this.$message.error("Please check your info.")
          return false;
        }
      })
    },
    submit: function () {
      for (let i = this.current_pre + this.current_point; i <= this.current_pre + 20; i++) {
        let current_label = 'Group' + this.userinfo.group +'_'+ this.current_point;
        if (!(current_label in this.labeled_data)) {
          this.$message.error("Please check image group " + (i - this.current_pre))
          return false
        }
      }
      //检查成功
      let send_data = {
        'data': JSON.stringify(this.labeled_data),
        'uuid': localStorage.getItem("uuid"),
        'uid': localStorage.getItem('uid'),
        'finish_time': new Date().getTime()
      }
      instance.post('/submit', send_data).then((res) => {
        if (res.data.code === 1) {
          this.$router.push({name: 'Thanks'})
        } else {
          this.$message.error("please try again later.")
        }
      }).catch(() => {
        this.$message.error("Network Error!")
      })
    },
    create_imgs: function () {
      this.current_imgs = []
      let base_url = "https://img.chatbot.fans/20221103_semi/"
      if (this.current_point >= 1 && this.current_point <= 20) {
        for (let i = 1; i <= this.current_img_num; i++) {
          this.current_imgs.push(base_url + `${(this.current_pre + this.current_point).toString().padStart(3, '0')}_${i}.jpg`)
        }
      }
    },
  },

  computed: {
    img_height: function () {
      if (this.current_img_num === 3) {
        return (this.clientHeight - 280) + 'px'
      } else {
        return (this.clientHeight / 2 - 90) + 'px'
      }
    },
    prev_disabled: function () {
      return this.current_point === 1;
    },
    next_disabled: function () {
      return this.current_point === 20;
    },
    finish_51: function () {
      for (let i = this.current_pre + this.current_point; i <= this.current_pre + 20; i++) {
        let current_label = 'Group' + this.userinfo.group +'_'+ this.current_point;
        if (!(current_label in this.labeled_data)) {
          return false
        }
      }
      return true
    }
  },
  created() {
    this.pre_url = this.$route.params.group;
    switch (this.pre_url){
      case 'bai':
        this.group = 1
        break
      case 'ri':
        this.group = 2
        break
      case 'yi':
        this.group = 3
        break
      case 'shan':
        this.group = 4
        break
      case 'jin':
        this.group = 5
        break
    }
    if (this.group > 0 && this.group < 6) {
      this.current_model = this.$route.params.model
      //默认是三张图，一行排列
      this.spans = 12;
      this.current_img_num = 4;
      this.current_pre = (this.group  - 1) * 20
      console.log(this.current_pre)
      // for (let i = this.current_pre + this.current_point; i <= 50 + this.current_pre; i++) {
      //   console.log(this.$route.params.model + i)
      //   this.labeled_data[this.$route.params.model + i.toString()] = ''
      // }
      this.current_selected = this.current_pre + this.current_point
    } else {
      this.$message.error("Wrong URL Parameter!");
      return false;
    }
    this.create_imgs()
  },
  mounted() {
    if (this._isMobile()) {
      alert('Please use your computer to open the page！');
      this.$loading({
        lock: true,
        text: 'Please use your computer to open the page!',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.pre_url = this.$route.params.group;
      switch (this.pre_url){
        case 'bai':
          this.group = 1
              break
        case 'ri':
          this.group = 2
              break
        case 'yi':
          this.group = 3
              break
        case 'shan':
          this.group = 4
              break
        case 'jin':
          this.group = 5
              break
      }
    }
    // 获取浏览器可视区域高度
    this.clientHeight = `${document.documentElement.clientHeight}`          //document.body.clientWidth;
    let that = this
    window.onresize = function temp() {
      that.clientHeight = `${document.documentElement.clientHeight}`;
    };
  }
}
</script>

<style scoped>
.header {
  height: 64px;
  background-color: #1976D2;
}

</style>
